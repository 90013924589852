import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Modal, Row, Skeleton, Space, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { act, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';
import getTableColumns, {
  getRowClassName,
  MyImageIcon,
  NJVAddButton,
  NJVButton,
  NJVEditButton,
  NJVInput,
  NJVTable,
  NJVTimeRangePicker,
  TitleLevel3,
} from '../../component/core-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';

const ChargingStationPage = () => {
  const type = 'CHARGING_STATION';
  const pageSize = Constant.pageSize;
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, toggleDataFetching] = useToggle(false);
  const [selectedId, setSelectedId] = useState();
  const [isModelOpen, toggleModelOpen] = useToggle(false);
  const [filterData, setFilterData] = useState({});
  const [rowPickers, setRowPickers] = useState({});
  const [editingIndex, setEditingIndex] = useState(null);
  const [activePickerRowIndex, setActivePickerRowIndex] = useState(null);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [active, setActive] = useState(false)

  const isEditing = (index) => editingIndex === index;

  const handlePickerChange = async (rowIndex, pickerId, pickerIndex, record, value) => {
    // console.log("val:", value);
    // console.log("chrecord:", record);
    // console.log("pickerId:", pickerId);
    setActive(false)

    const formattedFromTime = dayjs(value[0]).format('hh:mm A');
    const formattedToTime = dayjs(value[1]).format('hh:mm A');
    // console.log("Formatted From:", formattedFromTime);
    // console.log("Formatted To:", formattedToTime);

    const existingData = record.chargingStationElectricityDTOS || [];

    const updatedChargingStationElectricityDTOS = existingData.map((item, index) => {
      if (index === pickerIndex) {
        return {
          index: index + 1,
          fromTime: formattedFromTime,
          toTime: formattedToTime,
        };
      }
      return item;
    });

    if (pickerIndex >= existingData.length) {
      updatedChargingStationElectricityDTOS.push({
        index: updatedChargingStationElectricityDTOS.length + 1,
        fromTime: formattedFromTime,
        toTime: formattedToTime,
      });
    }

    // console.log("Updated API Data:", updatedChargingStationElectricityDTOS);

    await ApiHandler({
      url: Api.charging_station_update_electricity,
      method: HTTP_METHOD.PUT,
      requestData: { chargingStationElectricityDTOS: updatedChargingStationElectricityDTOS },
      specificId: record.id,
    })
      .then(() => {
        console.log("Data updated successfully");
        setRowPickers((prevState) => {
          const updatedPickers = { ...prevState };

          // console.log("updatePicker", updatedPickers)

          if (updatedPickers[rowIndex]) {
            updatedPickers[rowIndex] = updatedPickers[rowIndex].filter((picker) => picker.id !== pickerId);
          }

          return updatedPickers;
        });
      })
      .finally(() => fetchData(null, pickerId, rowIndex));
  };

  const addPicker = (rowIndex, picker, pickerIndex) => {
    console.log("rowIndx", rowIndex)
    console.log("active", active)
    // console.log("pikerIndex:", pickerIndex + 1)
    const newPicker = { id: Date.now() };
    if(!active){
    setRowPickers((prev) => {
      const updatedPickers = [...(prev[rowIndex] || []), newPicker];
      return { ...prev, [rowIndex]: updatedPickers };
    });

    setActive(true)
  }else{
    message.warning("Please select the previous time!")
  }
    setActivePickerRowIndex(pickerIndex + 1);
    setActiveRowIndex(rowIndex)
  };

  const edit = (index, record) => {
    setEditingIndex(index);
    setRowPickers((prevState) => {
      const updatedPickers = { ...prevState };
      // console.log("updatedP:", updatedPickers)
      if (!updatedPickers[index] || updatedPickers[index].length === 0) {
        updatedPickers[index] = [{ id: Date.now() }];
        setActive(true)
      }
      return updatedPickers;
    });
  };

  const cancelEdit = async (rowIndex, pickId, pickerId, pickerIndex, record) => {
    setActive(false)
    // console.log("pind",  pickerIndex)
    // console.log("pindId",  pickId)
    setRowPickers((prevState) => {
      const updatedPickers = { ...prevState };

      // console.log("updatePicker", updatedPickers)

      if (updatedPickers[rowIndex]) {
        updatedPickers[rowIndex] = updatedPickers[rowIndex].filter((picker) => picker.id !== pickId)
      }
      return updatedPickers;
    });

    setActivePickerRowIndex((prev) => prev - 1)
    // setActiveRowIndex(null)

    // console.log("PickerId:", pickerId)
    // if (value) {
    const updatedChargingStationElectricityDTOS = record.chargingStationElectricityDTOS?.filter((item) => {
      // console.log("itemIndex:", item.index)
      return item.index !== pickerId
    });

    // console.log("Updated chargingStationElectricityDTOS:", updatedChargingStationElectricityDTOS);

    await ApiHandler({
      url: Api.charging_station_update_electricity,
      method: HTTP_METHOD.PUT,
      requestData: { chargingStationElectricityDTOS: updatedChargingStationElectricityDTOS },
      specificId: record.id,
    }).finally(() => fetchData());
    // }
  };

  const renderPickers = (rowIndex, record) => {
    const allPickers = [
      ...(record.chargingStationElectricityDTOS || []),
      ...(rowPickers[rowIndex] || []),
    ];
    // console.log("rowIndex:", rowIndex);

    // console.log("activerowIndex:", activePickerRowIndex);

    return (
      <div>
        {allPickers.map((picker, pickerIndex) => {
          // console.log("picker:", picker);
          // console.log("pickerIndex:", pickerIndex);

          const defaultTime = record.chargingStationElectricityDTOS?.[pickerIndex]
            ? [
              dayjs(record.chargingStationElectricityDTOS[pickerIndex].fromTime, 'hh:mm A'),
              dayjs(record.chargingStationElectricityDTOS[pickerIndex].toTime, 'hh:mm A'),
            ]
            : [];

          return (
            <Space
              // key={picker.id || pickerIndex}
              style={{ display: 'flex', marginBottom: 8 }}
            >
              <Form.Item
                name={`fromTime_${rowIndex}_${picker.id || `picker-${pickerIndex}`}`}
                style={{ margin: 0 }}
              >
                { (pickerIndex !== allPickers.length - 1 && activeRowIndex === rowIndex && active) ?  (
                <NJVTimeRangePicker
                  defaultValue={defaultTime}
                  use12Hours
                  format="hh:mm A"
                  style={{ marginRight: 8 }}
                  onChange={(value) => handlePickerChange(rowIndex, picker.id, pickerIndex, record, value)}
                  allowClear={false}
                  disabled={true}
                />
                ) : <NJVTimeRangePicker
                defaultValue={defaultTime}
                use12Hours
                format="hh:mm A"
                style={{ marginRight: 8 }}
                onChange={(value) => handlePickerChange(rowIndex, picker.id, pickerIndex, record, value)}
                allowClear={false}
              />
        }
              </Form.Item>
              {pickerIndex === allPickers.length - 1 && (
                <>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    onClick={() => addPicker(rowIndex, picker, pickerIndex)}
                  />
                </>
              )}
              { active && activeRowIndex === rowIndex ?
              <>
              {pickerIndex === allPickers.length - 1 && (
                (activeRowIndex === null || activeRowIndex === rowIndex) ? (
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => cancelEdit(rowIndex, picker.id, picker.index, pickerIndex, record)}
                    danger
                  />
                ) : <Button
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => cancelEdit(rowIndex, picker.id, picker.index, pickerIndex, record)}
                danger
              />
              )}
              </>
              : <Button
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => cancelEdit(rowIndex, picker.id, picker.index, pickerIndex, record)}
              danger
            />
        }
            </Space>
          );
        })}
      </div>
    );
  };


  const fetchData = (pageNumber, pickerId, rowIndex) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      name: filterData?.searchFullName?.trim() ? filterData?.searchFullName?.trim() : '',
      address: filterData?.searchAddress?.trim() ? filterData?.searchAddress?.trim() : '',
    };
    ApiHandler({
      url: Api.charging_station_all,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        const content = response?.content;
        setData(content);
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
      })
      .catch((error) => {
        message.error(error);
      })
      .finally(() => {
        toggleDataFetching(false)
        setRowPickers((prevState) => {
          const updatedPickers = { ...prevState };

          // console.log("updatePicker", updatedPickers)

          if (updatedPickers[rowIndex]) {
            updatedPickers[rowIndex] = updatedPickers[rowIndex].filter((picker) => picker.id !== pickerId);
          }

          return updatedPickers;
        });
      })
  };

  const handleSearch = () => {
    fetchData();
  };

  useEffect(() => {
    toggleDataFetching();
    fetchData();
  }, []);

  const deleteStation = (id) => {
    ApiHandler({
      url: Api.charging_station,
      method: HTTP_METHOD.DELETE,
      specificId: id,
    }).finally(() => {
      toggleModelOpen(false);
      fetchData();
    });
  };

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
      title: 'Electricity Availability',
      dataIndex: 'chargingStationElectricityDTOS',
      render: (_, record, index) => {
        // console.log("length:", record.chargingStationElectricityDTOS);
        // console.log("rowPicker:", rowPickers)

        const editable = isEditing(index);
        const hasData = editable && rowPickers[index]?.length;

        return (hasData || record.chargingStationElectricityDTOS?.length > 0) ? (
          <Space direction="horizontal">
            <Space direction="vertical">
              {renderPickers(index, record)}
            </Space>
          </Space>
        ) : (
          <Button
            shape="circle"
            onClick={() => edit(index, record)}
            style={{ marginRight: 10 }}
            type="primary"
          >
            <MyImageIcon />
          </Button>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <>
          {status === 'Active' ? (
            <Tag color="#87d068">{Constant.userStatus[status]}</Tag>
          ) : (
            <Tag color="#cd201f">{Constant.userStatus[status]}</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Action',
      textAlign: 'center',
      render: (row) => (
        <Space>
          <Link to={`${CustomPath.charging_station_update}/${row.id}`}>
            <NJVEditButton type={type} />
          </Link>
          <Button
            size={'middle'}
            type="primary"
            danger
            shape="circle"
            onClick={() => {
              setSelectedId(row.id);
              toggleModelOpen();
            }}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };

  const tableColumns = getTableColumns(columns, type);
  return (
    <>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Charging Station'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Link to={CustomPath.charging_station_form}>
            <NJVAddButton type={type} />
          </Link>
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by name"
            onChange={(e) => onChangeFilter('searchFullName', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by address"
            onChange={(e) => onChangeFilter('searchAddress', e.target.value)}
          />
        </Col>
        <Col span={3}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          {isFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowKey="id"
              style={{ borderRadius: 10 }}
              columns={tableColumns}
              dataSource={data}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
      <Modal
        title="Delete Charging Station"
        open={isModelOpen}
        onOk={() => deleteStation(selectedId)}
        okType="danger"
        onCancel={toggleModelOpen}
      >
        <p>Are you sure want to delete?</p>
      </Modal>
    </>
  );
};

export default ChargingStationPage;
