import { Card, Col, Row, Statistic } from 'antd';
import React, { useEffect, useState } from 'react';
import { ApiHandler } from '../../network/network-manager';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import Api from '../../network/api';
import CountUp from 'react-countup';

const titleStyle = {
  fontSize: 20,
  fontWeight: '600',
};
const subtitleStyle = {
  fontSize: 17,
  fontWeight: '500',
};
const formatter = (value) => <CountUp end={value} separator="," />;
const Dashboard = () => {
  const [incomeSummary, setIncomeSummary] = useState({});
  useEffect(() => {
    ApiHandler({
      url: Api.daily_income_summary,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
    })
      .then((response) => {
        setIncomeSummary(response);
      })
      .catch(() => {})
      .finally(() => {});
  }, []);
  return (
    <Row>
      <Col span={8}>
        <Card bordered={false} className="card-shadow">
          <Row gutter={[16, 15]}>
            <Col span={24}>
              <Statistic
                title={<span style={titleStyle}>Today Income</span>}
                value={incomeSummary?.totalIncome || 0}
                valueStyle={{
                  color: '#3f8600',
                  fontSize: 25,
                }}
                suffix={<span style={{ fontSize: 21 }}>MMK</span>}
                formatter={formatter}
              />
            </Col>
            <Col span={12}>
              <Statistic
                title={<div style={subtitleStyle}>Cash</div>}
                value={incomeSummary?.cashAmount || 0}
                valueStyle={{
                  color: '#3f8600',
                  fontSize: 19,
                }}
                suffix={<span style={{ fontSize: 15 }}>MMK</span>}
                formatter={formatter}
              />
            </Col>
            <Col span={12}>
              <Statistic
                title={<div style={subtitleStyle}>KPay</div>}
                value={incomeSummary?.kpayAmount || 0}
                valueStyle={{
                  color: '#3f8600',
                  fontSize: 19,
                }}
                suffix={<span style={{ fontSize: 15 }}>MMK</span>}
                formatter={formatter}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Dashboard;
