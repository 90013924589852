import { ArrowLeftOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {
  AutoComplete,
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Drawer,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Tag,
  Typography,
} from 'antd';
import { getDistance } from 'geolib';
import mapboxgl from 'mapbox-gl';

import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SockJS from 'sockjs-client';
import { over } from 'stompjs';
import '../../App.css';
import { NJVInput } from '../../component/core-component';
import { SvgMarkerFilled, SvgProblemSolving } from '../../component/custom-svg-icon';
import Theme from '../../component/theme';
import { mapBoxAccessToken } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';

const { Title, Text } = Typography;

const defaultProfile = require('../../assets/default_profile.png');

const heatMapLevel = {
  low: 14,
  medium: 19,
  high: 24,
};

const labelStyle = {
  fontSize: '13px',
  fontWeight: '500',
  marginBlock: 5,
  color: 'gray',
};
const contentStyle = {
  fontSize: '15px',
  fontWeight: '500',
};

const calculateDistance = (fromLat, fromLng, toLat, toLng) => {
  if (fromLat && fromLng && toLat && toLng) {
    const distance = getDistance({ latitude: fromLat, longitude: fromLng }, { latitude: toLat, longitude: toLng });
    return distance / 1000;
  }
  return 0.0;
};

const tripMarkerEL = document.createElement('div');
tripMarkerEL.style.backgroundImage = `url(/images/need-action-marker.png)`;
tripMarkerEL.style.width = '50px';
tripMarkerEL.style.height = '50px';
tripMarkerEL.style.marginTop = '-30px';
tripMarkerEL.style.backgroundSize = 'contain';
tripMarkerEL.style.backgroundRepeat = 'no-repeat';
tripMarkerEL.style.transformOrigin = 'center';

const DriverLocationMap = () => {
  let stompClient = null;

  const mapContainerRef = useRef();
  const mapRef = useRef();
  const [socketConnected, setSocketConnected] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [markerCenter, setMarkerCenter] = useState([96.1588, 16.7945]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [driverData, setDriverData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [region, setRegion] = useState(2);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [zIndex, setZIndex] = useState(1);
  const [heatMapData, setHeatMapData] = useState({});

  const [selectedTrip, setSelectedTrip] = useState(null);
  const [selectedTripMarker, setSelectedTripMarker] = useState(null);
  const [openDriverAssignmentModal, setOpenDriverAssignmentModal] = useState(false);
  const [assignableDrivers, setAssignableDrivers] = useState([]);
  const [assigning, setAssigning] = useState(false);
  const [assignDriverId, setAssignDriverId] = useState(null);
  const [actionRequiredCount, setActionRequiredCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const selectedStatusRef = useRef(selectedStatus);

  useEffect(() => {
    selectedStatusRef.current = selectedStatus;
  }, [selectedStatus]);

  const handleSelect = (selectedValue) => {
    const requestParams = {
      selectedValue: selectedValue.trim(),
    };

    const matchMarker = markers.find(
      (marker) =>
        marker.driverName === requestParams.selectedValue || marker.vehicleNumber === requestParams.selectedValue,
    );

    if (matchMarker && mapRef.current) {
      mapRef.current.flyTo({
        center: [matchMarker.lng, matchMarker.lat],
        zoom: 14,
        essential: true,
        duration: 1200,
      });
    }
  };

  const handleSearch = (value) => {
    const filteredOptions = markers
      ?.filter(
        (marker) =>
          (marker.driverName && marker.driverName.toLowerCase().includes(value.toLowerCase())) ||
          (marker.vehiclePlateNumber &&
            marker.vehiclePlateNumber
              .replace(/[^a-zA-Z0-9]/g, '')
              .toLowerCase()
              .includes(value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase())),
      )
      .map((marker) => ({
        value: marker.driverName || marker.vehiclePlateNumber,
        label: `${marker.driverName} (${marker.vehiclePlateNumber ? marker.vehiclePlateNumber : 'No connected vehicle'})`,
      }));

    setOptions(filteredOptions);
  };

  const backToHome = () => navigate(CustomPath.active_driver_list);

  const connectSocket = () => {
    try {
      const Sock = new SockJS(Api.driver_location_host + '/ws');
      stompClient = over(Sock);

      stompClient.connect(
        {},
        () => {
          setSocketConnected(true);
          stompClient.subscribe(
            `/driver/${region}/locations`,
            (payload) => {
              onReceiveDriverLocation(payload);
            },
            (error) => {
              console.error('Subscription error:', error);
            },
          );
        },
        (error) => {
          setSocketConnected(false);
          console.error('WebSocket connection error:', error);
        },
      );
    } catch (error) {
      console.error('Error connecting to WebSocket:', error);
    }
  };

  useEffect(() => {
    mapboxgl.accessToken = mapBoxAccessToken;
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: markerCenter,
      zoom: 12,
    });
    const nav = new mapboxgl.NavigationControl();
    mapRef.current.addControl(nav, 'bottom-right');
    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, [markerCenter]);

  const fetchNeedActionTrips = () => {
    const requestParams = {
      size: 50,
      page: 0,
      tripStatus: 'DriverNotFound',
    };
    ApiHandler({
      url: Api.trip_history_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setupNeedActionMarkers(response?.content);
        setActionRequiredCount(response?.content?.length);
      })
      .catch(() => { })
      .finally(() => { });
  };

  const setupNeedActionMarkers = (list) => {
    list?.forEach((trip) => {
      const coordinate = [trip?.tripPickUpLocation?.longitude, trip?.tripPickUpLocation?.latitude];
      const offsetPosition = (lat, lng) => {
        const offset = 0.00001;
        const randomLat = lat + (Math.random() - 0.5) * offset;
        const randomLng = lng + (Math.random() - 0.5) * offset;
        return [randomLng, randomLat];
      };

      const marker = new mapboxgl.Marker({ element: tripMarkerEL })
        .setLngLat(offsetPosition(coordinate[1], coordinate[0]))
        .addTo(mapRef.current);
      marker.getElement().addEventListener('click', () => {
        setOpenDriverAssignmentModal(true);
        setSelectedTrip(trip);
        setSelectedTripMarker(marker);
      });
    });
  };

  const MarkerComponent = (marker) => {
    const el = document.createElement('div');
    el.style.width = '30px';
    el.style.height = '45px';
    el.style.backgroundSize = 'contain';
    el.style.backgroundRepeat = 'no-repeat';
    el.style.transformOrigin = 'center';

    if (marker.tripStatus === 'Active') {
      el.style.backgroundImage = 'url(/images/marker/yellow-car.png)';
    } else if (marker.driverStatus === 'Offline') {
      el.style.backgroundImage = 'url(/images/marker/gray-car.png)';
    } else if (marker.driverStatus === 'Inactive') {
      el.style.backgroundImage = 'url(/images/marker/muted-gold-car.png)';
    } else if (marker.driverStatus === 'Idle') {
      el.style.backgroundImage = 'url(/images/marker/purple-car.png)';
    } else if (marker.driverStatus === 'Available') {
      el.style.backgroundImage = 'url(/images/marker/green-car.png)';
    } else if (marker.driverStatus === 'Busy') {
      el.style.backgroundImage = 'url(/images/marker/blue-car.png)';
    } else {
      el.style.backgroundImage = 'url(/images/marker/green-car.png)';
    }

    const popupNode = document.createElement('div');
    popupNode.innerHTML = `
      <div style="display: flex; align-items: center; height: 17px; cursor: pointer; margin: 0; padding: 0">
        <img src="${marker.driverPhotoUrl ? marker.driverPhotoUrl : '/images/default_profile.png'}" style="width: 25px; height: 25px; border-radius: 50%; margin-right: 8px;" />
        <div style="display: flex; flex-direction: column;">
          <span style="font-weight: bold; line-height: 2;">${marker.driverName}</span>
          <span style="font-size: 12px; line-height: 1;">${marker.vehiclePlateNumber ? marker.vehiclePlateNumber : ''}</span>
        </div>
      </div>`;

    const initialPopup = new mapboxgl.Popup({
      offset: 25,
      closeOnClick: false,
      closeButton: false,
      openOnClick: false,
    }).setDOMContent(popupNode);

    const offsetPosition = (lat, lng) => {
      const offset = 0.00001;
      const randomLat = lat + (Math.random() - 0.5) * offset;
      const randomLng = lng + (Math.random() - 0.5) * offset;
      return [randomLng, randomLat];
    };

    const newMarker = new mapboxgl.Marker({ element: el })
      .setLngLat(offsetPosition(marker.lat, marker.lng))
      .addTo(mapRef.current)
      .setPopup(initialPopup);

    newMarker.setRotation(marker.direction);
    initialPopup.addTo(mapRef.current);

    newMarker.getElement().onclick = (e) => e.stopPropagation();

    newMarker.getElement().addEventListener('click', () => {
      setIsModalOpen(true);
      setModalData(marker);
    });

    popupNode.addEventListener('click', () => {
      setIsModalOpen(true);
      setModalData(marker);
    });

    initialPopup
      .getElement()
      .querySelector('.mapboxgl-popup-content')
      .addEventListener('click', function () {
        setIsModalOpen(true);
        setModalData(marker);
      });

    const popupContent = initialPopup.getElement().querySelector('.mapboxgl-popup-content');
    popupContent.style.cursor = 'pointer';

    popupContent.addEventListener('click', function () {
      setZIndex((prev) => prev + 1);
      initialPopup.getElement().style.zIndex = zIndex;
    });

    return newMarker;
  };

  const getAllDrivers = useCallback(() => {
    const requestParams = { regionId: region };
    ApiHandler({
      url: Api.driver_location,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setDriverData(response);
      })
      .catch(() => { });
  }, [region]);

  const getHeatMapData = useCallback(() => {
    ApiHandler({
      url: Api.heat_map,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
    })
      .then((response) => {
        const features = [];
        response?.forEach((item) => {
          item?.data?.forEach((dto) => {
            features.push({
              type: 'Feature',
              properties: {
                dbh: item?.level ? heatMapLevel[item?.level] : 8,
              },
              geometry: {
                type: 'Point',
                coordinates: [dto.longitude, dto.latitude],
              },
            });
          });
        });
        setHeatMapData({
          type: 'FeatureCollection',
          features,
        });
      })
      .catch(() => { });
  }, []);

  useEffect(() => {
    markers?.forEach((marker) => {
      marker.markerInstance.remove();
    });
    const filteredMarkers = Array.isArray(driverData)
      ? driverData.filter(
        (data) => data?.regionId === Number(region) && (!selectedStatus || data.driverStatus === selectedStatus),
      )
      : [];

    const updatedMarkers = markers.map((marker) => {
      const matchedMarkerData = filteredMarkers.find((data) => data.driverId === marker.driverId);

      if (matchedMarkerData) {
        marker.markerInstance.remove();
        const newMarkerInstance = MarkerComponent(matchedMarkerData);
        return {
          ...marker,
          lat: matchedMarkerData.lat,
          lng: matchedMarkerData.lng,
          driverStatus: matchedMarkerData.driverStatus,
          markerInstance: newMarkerInstance,
        };
      }
      return marker;
    });

    const newMarkers = filteredMarkers
      .filter((data) => !markers.some((marker) => marker.driverId === data.driverId))
      .map((newMarkerData) => ({
        driverId: newMarkerData.driverId,
        lat: newMarkerData.lat,
        lng: newMarkerData.lng,
        markerInstance: MarkerComponent(newMarkerData),
        driverPhotoUrl: newMarkerData.driverPhotoUrl,
        driverStatus: newMarkerData.driverStatus,
        direction: newMarkerData.direction,
        driverName: newMarkerData.driverName,
        vehiclePlateNumber: newMarkerData.vehiclePlateNumber,
      }));

    setMarkers([...updatedMarkers, ...newMarkers]);
  }, [driverData, selectedStatus, region]);

  useEffect(() => {
    Promise.all([getAllDrivers(), getHeatMapData(), fetchNeedActionTrips()]);
  }, []);

  // update heat map data
  useEffect(() => {
    if (heatMapData && heatMapData?.features?.length > 0) {
      mapRef.current.on('load', () => {
        if (mapRef.current.getSource('trees') !== undefined) {
          mapRef.current.removeLayer('trees-heat');
          mapRef.current.removeSource('trees');
        }
        mapRef.current.addSource('trees', {
          type: 'geojson',
          data: heatMapData,
        });
        mapRef.current.addLayer(
          {
            id: 'trees-heat',
            type: 'heatmap',
            source: 'trees',
            maxzoom: 18,
            paint: {
              // increase weight as diameter breast height increases
              'heatmap-weight': {
                property: 'dbh',
                type: 'exponential',
                stops: [
                  [1, 0],
                  [62, 1],
                ],
              },
              // increase intensity as zoom level increases
              'heatmap-intensity': {
                stops: [
                  [11, 1],
                  [15, 3],
                ],
              },
              // assign color values be applied to points depending on their density
              'heatmap-color': [
                'interpolate',
                ['linear'],
                ['heatmap-density'],
                0,
                'rgba(236,222,239,0)',
                0.2,
                'rgb(255, 128, 128)',
                0.4,
                'rgb(255, 102, 102)',
                0.6,
                'rgb(255, 77, 77)',
                0.8,
                'rgb(255, 51, 51)',
              ],
              // increase radius as zoom increases
              'heatmap-radius': {
                stops: [
                  [11, 15],
                  [15, 20],
                ],
              },
              // decrease opacity to transition into the circle layer
              'heatmap-opacity': {
                default: 1,
                stops: [
                  [20, 1],
                  [20, 0],
                ],
              },
            },
          },
          'waterway-label',
        );
      });
    }
  }, [heatMapData]);

  useEffect(() => {
    if (!socketConnected) {
      connectSocket();
    }
  }, [socketConnected, region]);

  const onReceiveDriverLocation = (payload) => {
    if (!payload) return;

    try {
      const markerData = JSON.parse(payload.body);

      if (!mapRef.current) return;

      setMarkers((prevMarkers) => {
        const existingMarkerIndex = prevMarkers?.findIndex((marker) => marker.driverId === markerData.driverId);

        if (existingMarkerIndex !== -1) {
          const existingMarker = prevMarkers[existingMarkerIndex];

          const updatedMarkers = [...prevMarkers];
          updatedMarkers[existingMarkerIndex] = { ...existingMarker, ...markerData };

          if (existingMarker.lat !== markerData.lat || existingMarker.lng !== markerData.lng) {
            if (!selectedStatusRef.current || selectedStatusRef.current === markerData.status) {
              updatedMarkers[existingMarkerIndex] = { ...existingMarker, ...markerData };
            }

            const startCoordinates = [existingMarker.lng, existingMarker.lat];
            const endCoordinates = [markerData.lng, markerData.lat];

            animateMarker(existingMarker.markerInstance, startCoordinates, endCoordinates);
          }

          existingMarker.markerInstance.setRotation(markerData.direction);

          const popupContent = `
              <div style="display: flex; align-items: center; height: 17px">
                <img src="${existingMarker.driverPhotoUrl ? existingMarker.driverPhotoUrl : '/images/default_profile.png'}" style="width: 25px; height: 25px; border-radius: 50%; margin-right: 8px;" />
                <div style="display: flex; flex-direction: column;">
                  <span style="font-weight: bold;">${markerData.driverName}</span>
                  <span style="font-size: small;">${markerData.vehiclePlateNumber}</span>
                </div>
              </div>`;
          existingMarker.markerInstance.getPopup().setHTML(popupContent);

          const markerElement = existingMarker.markerInstance.getElement();
          if (markerData.tripStatus === 'Active') {
            markerElement.style.backgroundImage = 'url(/images/marker/yellow-car.png)';
          } else if (markerData.driverStatus === 'Inactive' || markerData.driverStatus === 'Offline') {
            markerElement.style.backgroundImage = 'url(/images/marker/gray-car.png)';
          } else if (markerData.driverStatus === 'Idle') {
            markerElement.style.backgroundImage = 'url(/images/marker/purple-car.png)';
          } else if (markerData.driverStatus === 'Available') {
            markerElement.style.backgroundImage = 'url(/images/marker/green-car.png)';
          } else if (markerData.driverStatus === 'Busy') {
            markerElement.style.backgroundImage = 'url(/images/marker/purple-car.png)';
          } else {
            markerElement.style.backgroundImage = 'url(/images/marker/green-car.png)';
          }

          markerElement.addEventListener('click', () => {
            setIsModalOpen(true);
            setModalData(markerData);
          });

          updatedMarkers[existingMarkerIndex] = {
            ...existingMarker,
            lat: markerData.lat,
            lng: markerData.lng,
            direction: markerData.direction,
            driverName: markerData.driverName,
            driverStatus: markerData.driverStatus,
            vehiclePlateNumber: markerData.vehiclePlateNumber,
          };
          setDriverData((prev) => {
            if (!Array.isArray(prev)) {
              console.error('Unexpected state format. Initializing to an empty array.');
              prev = [];
            }

            const existingIndex = prev.findIndex((driver) => driver.driverId === markerData.driverId);
            if (existingIndex !== -1) {
              prev[existingIndex] = { ...prev[existingIndex], ...markerData };
              return [...prev];
            }
            return [...prev, markerData];
          });

          return updatedMarkers;
        } else {
          if (!selectedStatusRef.current || selectedStatusRef.current === markerData.driverStatus) {
            const markerExists = prevMarkers.some((marker) => marker.driverId === markerData.driverId);

            if (markerExists) {
              return prevMarkers.map((marker) => {
                if (marker.driverId === markerData.driverId) {
                  marker.markerInstance.setLatLng([markerData.lat, markerData.lng]);

                  return {
                    ...marker,
                    lat: markerData.lat,
                    lng: markerData.lng,
                  };
                }
                return marker;
              });
            } else {
              const newMarker = MarkerComponent(markerData);

              return [
                ...prevMarkers,
                {
                  driverId: markerData.driverId,
                  lat: markerData.lat,
                  lng: markerData.lng,
                  markerInstance: newMarker,
                  direction: markerData.direction,
                  driverName: markerData.driverName,
                  vehiclePlateNumber: markerData.vehiclePlateNumber,
                },
              ];
            }
          }
          setDriverData((prev) => {
            if (!Array.isArray(prev)) {
              prev = [];
            }

            const existingIndex = prev.findIndex((driver) => driver.driverId === markerData.driverId);
            if (existingIndex !== -1) {
              prev[existingIndex] = { ...prev[existingIndex], ...markerData };
              return [...prev];
            }
            return [...prev, markerData];
          });

          return markers;
        }
      });
    } catch (error) {
      console.error('Error parsing WebSocket data:', error);
    }
  };

  const animateMarker = (markerInstance, startCoordinates, endCoordinates) => {
    const startTime = performance.now();
    const duration = 1000;

    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);

      const newLng = startCoordinates[0] + (endCoordinates[0] - startCoordinates[0]) * progress;
      const newLat = startCoordinates[1] + (endCoordinates[1] - startCoordinates[1]) * progress;

      if (!isNaN(newLng) && !isNaN(newLat)) {
        markerInstance.setLngLat([newLng, newLat]);
      }

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  };

  const onRegionChange = (value) => {
    markers?.forEach((marker) => {
      marker.markerInstance.remove();
    });
    mapRef.current.flyTo({
      center: value === '2' ? [96.1588, 16.7945] : value === '1' ? [96.0891, 21.9588] : '',
      zoom: 12,
      essential: true,
      duration: 1500,
    });

    // setSocketConnected(value !== Number(region) && false);
    setRegion(value);
  };

  const assignDriverToTrip = (tripId, driverId) => {
    setAssigning(true);
    setAssignDriverId(driverId);

    const url = Api.trip_instant_assign_driver_to_trip + '/' + tripId + '/' + driverId;
    ApiHandler({ url, method: HTTP_METHOD.PUT, disableShowMessage: true })
      .then(() => {
        selectedTripMarker?.remove();
        setOpenDriverAssignmentModal(false);
        setSelectedTrip(null);
        setSelectedTripMarker(null);
        setAssignDriverId(null);
        setSelectedTripMarker(null);
        message.success('Successfully assigned to the selected trip');
        if (actionRequiredCount > 0) {
          setActionRequiredCount(actionRequiredCount - 1);
        }
      })
      .catch(() => { })
      .finally(() => {
        setAssigning(false);
        setAssignDriverId(null);
      });
  };

  const onRefresh = () => {
    window.location.reload();
  };

  useEffect(() => {
    const list = [];
    driverData?.forEach((item) => {
      const distance = calculateDistance(
        item?.lat,
        item?.lng,
        selectedTrip?.tripPickUpLocation?.latitude,
        selectedTrip?.tripPickUpLocation?.longitude,
      );
      item['distance'] = distance;
      list.push(item);
    });
    const sortedList = list.sort((a, b) => {
      return a.distance - b.distance;
    });
    setAssignableDrivers(sortedList);
  }, [selectedTrip, driverData]);

  const filteredAssignableDrivers = assignableDrivers?.filter((item) =>
    (item?.driverName ?? '').toLowerCase().includes((searchQuery ?? '').toLowerCase()),
  );
  return (
    <>
      <Drawer
        title="Trip Detail"
        width={500}
        open={openDriverAssignmentModal}
        onClose={() => {
          setOpenDriverAssignmentModal(false);
          setSelectedTrip(null);
          setSelectedTripMarker(null);
          setAssignDriverId(null);
        }}
      >
        <div>
          <div>
            <div style={labelStyle}>Passenger Name</div>
            <div style={contentStyle}>{selectedTrip?.passengerDTO?.fullName}</div>
            <div style={labelStyle}>Passenger Phone Number</div>
            <div style={contentStyle}>{selectedTrip?.passengerDTO?.phoneNumber}</div>
          </div>
          <Divider style={{ marginBlock: 20 }} />
          <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
              <SvgMarkerFilled width={20} height={20} color={'#52c41a'} />
              <div style={{ ...labelStyle, marginLeft: 7 }}>Pick-Up Location</div>
            </div>
            <div style={contentStyle}>{selectedTrip?.tripPickUpLocation?.address}</div>
          </div>
          <div style={{ height: 25, borderLeft: '4px dotted gray', marginLeft: 50 }} />
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SvgMarkerFilled width={20} height={20} color={'#f5222d'} />
              <div style={{ ...labelStyle, marginLeft: 7 }}>Drop-Off Location</div>
            </div>
            <div style={contentStyle}>{selectedTrip?.tripDropOffLocation?.address}</div>
          </div>
          <Divider style={{ marginBlock: 20 }} />
          <div>
            <div style={labelStyle}>Assignable Drivers</div>
            <div>
              <NJVInput
                bgcolor={Theme.colors.light_gray}
                placeholder="Search"
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
            {filteredAssignableDrivers?.map((item) => (
              <div
                style={{
                  paddingBlock: 10,
                  backgroundColor: Theme.colors.card_bg_color,
                  borderRadius: 10,
                  paddingInline: 15,
                  marginBottom: 7,
                }}
                key={item.id}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Flex justify="space-between" align="center">
                    {item?.driverPhotoUrl ? (
                      <Avatar src={item.driverPhotoUrl} size={40} style={{ border: '1px dashed gray' }} />
                    ) : (
                      <Avatar src={defaultProfile} size={40} style={{ border: '1px dashed gray' }} />
                    )}

                    <div style={{ marginLeft: 10 }}>
                      <div style={{ fontSize: 16, fontWeight: '600' }}>{item?.driverName}</div>
                      <div style={{ fontSize: 12, color: 'gray', fontWeight: '600' }}>{item?.distance} km</div>
                    </div>
                  </Flex>

                  <Button
                    type="primary"
                    size="small"
                    disabled={assigning && assignDriverId !== item?.driverId && true}
                    loading={assigning && assignDriverId === item?.driverId}
                    onClick={() => {
                      assignDriverToTrip(selectedTrip.id, item?.driverId);
                    }}
                  >
                    Assign
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Drawer>
      <Modal open={isModalOpen} footer={false} onCancel={() => setIsModalOpen(false)} closable={true} width={280}>
        <div style={{ textAlign: 'center' }}>
          <Avatar src={modalData.driverPhotoUrl} size={80} />
          <Title level={4} style={{ marginTop: 6 }}>
            {modalData.driverName}
          </Title>
        </div>
        <div style={{ padding: '0 5px' }}>
          <Text strong>
            <span style={{ color: '#757573' }}>License Plate Number:</span>{' '}
            {modalData.vehiclePlateNumber ? modalData.vehiclePlateNumber : '-'}
          </Text>
          <div style={{ marginTop: '8px' }}>
            <Text strong>
              <span style={{ color: '#757573' }}>Driver Status:</span>{' '}
              {modalData?.driverStatus === 'Available' ? (
                <Tag color="green">{modalData.driverStatus}</Tag>
              ) : (
                modalData?.driverStatus
              )}
            </Text>
          </div>
          <div style={{ marginTop: '8px' }}>
            <Text strong>
              <span style={{ color: '#757573' }}>Driver Number:</span>{' '}
              {modalData.driverNumber ? modalData.driverNumber : '-'}
            </Text>
          </div>
        </div>
      </Modal>
      <div ref={mapContainerRef} style={{ width: '100%', height: '100vh', borderRadius: 5 }}></div>
      <Row gutter={12} style={{ margin: '10px 10px 0 10px' }}>
        <Col span={12} style={{ display: 'flex' }}>
          <Button onClick={backToHome} type="primary" icon={<ArrowLeftOutlined />}>
            Back
          </Button>
          <Button
            icon={<ReloadOutlined />}
            onClick={onRefresh}
            type="primary"
            style={{ backgroundColor: '#28a8fa', marginLeft: 10 }}
          >
            Refresh
          </Button>
          <Form layout="horizontal" style={{ width: '100%' }}>
            <Row style={{ width: '100%' }}>
              <Col span={9}>
                <Form.Item name="driverName">
                  <AutoComplete
                    options={options}
                    onSearch={handleSearch}
                    onSelect={handleSelect}
                    style={{ width: '100%', marginLeft: 12 }}
                  >
                    <Input
                      style={{
                        boxShadow: 'none',
                        borderColor: 'white',
                      }}
                      prefix={<SearchOutlined />}
                      placeholder="Search by DriverName or VehicleNumber"
                    />
                  </AutoComplete>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={6} />
        <Col span={3}>
          <Form.Item name="region">
            <Select placeholder="Select Region" onChange={onRegionChange} defaultValue="Yangon">
              <Select.Option value="2">Yangon</Select.Option>
              <Select.Option value="1">Mandalay</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="status">
            <Select
              placeholder="Select Status"
              allowClear
              onChange={(value) => {
                setSelectedStatus(value);
              }}
            >
              <Select.Option value="Available">Active</Select.Option>
              <Select.Option value="Inactive">Inactive</Select.Option>
              <Select.Option value="Idle">Idle</Select.Option>
              <Select.Option value="Busy">Busy</Select.Option>
              <Select.Option value="Offline">Offline</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          {actionRequiredCount !== 0 && (
            <Badge count={actionRequiredCount}>
              <div style={{ padding: '10px 8px 5px 10px', backgroundColor: 'white', borderRadius: 10, fontSize: 20 }}>
                <SvgProblemSolving width={20} height={20} color="red" />
              </div>
            </Badge>
          )}
        </Col>
      </Row>
    </>
  );
};

export default DriverLocationMap;
